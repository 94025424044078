
const messages = {
	appValEmail:
	{
		id: 'appValEmail',
        defaultMessage: 'Validador Email',
        description: ''
    },
    logoCorreos:
	{
        id: 'logoCorreos',
        defaultMessage: 'Logo Correos',
        descripcion: ''
    },
    validandoToken: 
    {
        id: 'validandoToken',
        defaultMessage: 'Validando Token...',
        description: ''
    },
    validacionCorrecta: 
    {
        id: 'validacionCorrecta',
        defaultMessage: '!Correo Electrónico {email} validado CORRECTAMENTE!',
        description: ''
    },
    detalleValidacionCorrecta: 
    {
        id: 'detalleValidacionCorrecta',
        defaultMessage: 'Esperamos que disfrutes de los servicios que Correos pone a su disposición.',
        description: ''
    },
    validacionIncorrecta: 
    {
        id: 'validacionIncorrecta',
        defaultMessage: '!No se ha podido validar la Cuenta de Correo!',
        description: ''
    },
    lblIrPaginaCorreos: 
    {
        id: 'lblIrPaginaCorreos',
        defaultMessage: 'Ir a la página inicial de Correos',
        description: ''
    }	
};


export default messages;