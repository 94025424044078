import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

// Mensajes
import languages from './languages';

import './main.scss';

import * as serviceWorker from './serviceWorker';
const languageCode = navigator.language.replace('-', '_');
const language = languageCode.split(/[-_]/)[0];

ReactDOM.render(
	<IntlProvider locale={language} messages={languages[languageCode]}>
		<ConfigProvider locale={languages['antd' + languageCode]}>
			<App />
		</ConfigProvider>
	</IntlProvider>,

	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
