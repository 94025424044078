import React, { Component } from "react";
import { Row, Col, Menu } from "antd";
import PropTypes from "prop-types";


class MHorizontal extends Component {
  
  render() {    
    return (
      <div className="MHorizontal">
        <Row>
          <Col span={1} />
          <Col>
            <Menu mode={this.props.modo} theme={this.props.tema}>
              <Menu.Item key="vacio"></Menu.Item>
            </Menu>
          </Col>
          <Col span={1} />
        </Row>
      </div>
    );
  }
}

MHorizontal.propTypes = {
  modo: PropTypes.string.isRequired,
  tema: PropTypes.string.isRequired
};

export default MHorizontal;