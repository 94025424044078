import React, { Component } from 'react';

import ValEmail from '../../components/ValEmail/ValEmail';

class ValEmailToken extends Component {

	render() {
		return (
			<ValEmail />			
		);
	}
}

export default ValEmailToken;