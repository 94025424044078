import React, { Component } from 'react';

import { Result, Spin, Button } from 'antd';
import AxiosInstance from '../../Api/Instance';

import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

// Funciones
import { urls } from "../../Api/ApiUrl";

// Mensajes
import messages from '../../languages/messages';

class ValEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Spining
			loading: false,
			estadoValidacion: null,
			tituloDescripcionEstadoValidacion: null,
			subTituloDescripcionEstadoValidacion: null
		}
	}

	componentDidMount() {
		this.validarToken();
	}

	/**
	 * Metodo que carga las estados petición público
	 */
	validarToken = () => {
		// Activamos spinner
		this.spinnerHandler(true);

		// Recuperamos token
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const token = params.get('token');
		console.error(token);
		console.log(token);
		console.debug(token);
		// Consultamos
		AxiosInstance.get(urls.VALIDAR_EMAIL_TOKEN.replace("{token}", token))
			.then(response => {
				this.spinnerHandler(false);
				console.error(response);
				console.log(response);
				console.debug(token);
				this.setState({
					estadoValidacion: 'success',
					tituloDescripcionEstadoValidacion: this.props.intl.formatMessage({ id: messages.validacionCorrecta.id }, { email: response.data.data.email }),
					subTituloDescripcionEstadoValidacion: this.props.intl.formatMessage(messages.detalleValidacionCorrecta)
				});
			}).catch(error => {
				let mensaje = null;

				if ((error) && (error.response) && (error.response.data) && (error.response.data) && (error.response.data.data) && (error.response.data.data.errors) && (error.response.data.data.errors.length > 0)) {
					const aux = Object.values(error.response.data.data.errors).toString();
					if ("" !== aux) {
						mensaje = aux;
					}
				}

				this.setState({
					estadoValidacion: 'error',
					tituloDescripcionEstadoValidacion: this.props.intl.formatMessage(messages.validacionIncorrecta),
					subTituloDescripcionEstadoValidacion: <p>{mensaje}</p>
				});

				this.spinnerHandler(false);
			});
	}

	/**
	 * Ir a la página de correos
	 */
	irPaginaCorreos = () => {
		if (typeof window !== 'undefined') {
			window.location.href = window._env_.REACT_APP_PAGINA_INICIAL_CORREOS;
		}
	}

	/**
	 * Gestion del spinner
	 */
	spinnerHandler = (activate) => {
		this.setState({
			loading: activate
		});
	}

	render() {
		return (
			this.state.estadoValidacion == null ? '' :
				<div className="ValEmail">
					<Spin tip={this.props.intl.formatMessage(messages.validandoToken)} spinning={this.state.loading}>
						<Result
							status={this.state.estadoValidacion}
							title={this.state.tituloDescripcionEstadoValidacion}
							subTitle={this.state.subTituloDescripcionEstadoValidacion}
							extra={[
								<Button type="primary" key="irPaginaCorreos" onClick={this.irPaginaCorreos}>
									<FormattedMessage {...messages.lblIrPaginaCorreos} />
								</Button>
							]}
						/>
					</Spin>
				</div>
		);
	}
}

export default injectIntl(ValEmail);