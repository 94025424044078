/* eslint no-console: ["error", { allow: ["error"] }] */

import axios from "axios";

export const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent    
    return config;
  },
  function (error) {
    // Do something with request error    
    // Registramos el error
    console.error(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    /* Any status code that lie within the range of 2xx cause this function to trigger
    (Do something with response data)*/
    return response;
  },
  function (error) {
    /* Any status codes that falls outside the range of 2xx cause this function to trigger
    (Do something with response error)*/
    // Registramos el error
    console.error(error);
    return Promise.reject(error);
  }
);

export default instance;