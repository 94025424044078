import React, { Component } from "react";
import { Row, Col} from "antd";
import PropTypes from "prop-types";
import LogoCorreos from "../../../../public/images/LogoCorreos2019_Azul.svg";
import {injectIntl} from 'react-intl';

class Header extends Component { 

  render() {
	const altProp = "Logo Correos";
    /*Logo y nombre de aplicacion (this.props.name) */

    return (
      <div className="header">
        <Row>
          <Col span={1} />
          <Col md={9} lg={7} xl={6} xxl={5}>
            <img src={LogoCorreos} alt={altProp} />
            <h1>{this.props.name}</h1>
          </Col>
          <Col md={8} lg={12} xl={14} xxl={15}/>
          <Col md={5} lg={4} xl={3} xxl={3} align="right" />                      
          <Col span={1} />
        </Row>
      </div>
    );
  }
}

Header.propTypes = {
  name: PropTypes.string.isRequired
};

export default injectIntl(Header);