const en = {
	appValEmail: 'Validador Email',
	logoCorreos: 'Logo Correos',
	validandoToken: 'Validando Token...',
	validacionCorrecta: '!Correo Electrónico {email} validado CORRECTAMENTE!',
	detalleValidacionCorrecta: 'Esperamos que disfrutes de los servicios que Correos pone a su disposición.',
	validacionIncorrecta: '!No se ha podido validar la Cuenta de Correo!',
	lblIrPaginaCorreos: 'Ir a la página inicial de Correos'
};

export default en;