/* eslint-disable */
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Row, Col, Layout} from "antd";

import {ValEmailToken} from "./pages";

// Componentes Transversales
import { Header, MHorizontal} from "./components/shared";

import {injectIntl} from 'react-intl';

//Mensajes
import messages from './languages/messages';

// Declararación del Content del Layout de antd
const { Content } = Layout;

class App extends Component {

  render() {    
    return (      
        <Router>
          <div className="App">
            <Layout>
              <Header name={this.props.intl.formatMessage(messages.appValEmail)} />
              <Layout>
              	<MHorizontal  modo="horizontal" tema="dark" />
              </Layout>
              <Layout>
                <Row>
                  <Col span={1} />
                  <Col lg={22}>
                    <Layout>                      
                      <Layout>                        
                        <Content>
                          <Switch>
                            {/* Cualquier página redirige a ValEmailToken */}
	                          <Route exact path="*" component={ValEmailToken} />
                          </Switch>
                        </Content>
                      </Layout>
                    </Layout>
                  </Col>
                  <Col span={1} />
                </Row>
              </Layout>
            </Layout>
          </div>
        </Router>      
    );
  }
}

export default injectIntl(App);